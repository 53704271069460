import axios from 'axios'
import config from '@/config'
import * as VueMethods from "@@/core/libraries/VueMethods";
import {getCookie} from "@@/core/libraries/VueMethods";

axios.defaults.baseURL = config.baseUrl;
if (VueMethods.isPart('admin')) {
  if (getCookie("adminAuthorization")) {
    axios.defaults.headers.common["Authorization"] = getCookie("adminAuthorization");
  }
}

export default {
  namespaced: true,
  state: () => ({
    data: null
  }),
  mutations: {
    setData(state, data) {
      state.data = data
    }
  },
  actions: {
    async fetchData({commit}) {
      const response = await axios.get('admin/dashboard')
      commit('setData', response.data.data.response)
      return response.data.data.response
    }
  },
  getters: {
    getData(state) {
      return state.data
    }
  }
}
