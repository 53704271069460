import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import adminRoutes from "./core/routes";
import config from '@/config'
import NumericDirective from './core/libraries/numericDirectives'
import Notifications from "vue-notification";
import Multiselect from "vue-multiselect";
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
import jalali from 'jalali-dayjs'

import Axios from "axios";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import swal from "sweetalert";
import './core/libraries/veeValidate'
import './core/libraries/directives'
import './core/libraries/filters'
import './assets/styles.css'
import './assets/styles.scss'
import pagination from "shetabit-laravel-vue-pagination"
import $ from 'jquery/dist/jquery.min.js'
import * as VueMethods from './core/libraries/VueMethods'
import { FulfillingBouncingCircleSpinner } from 'epic-spinners'
import store from './core/libraries/store'
import './core/libraries/core'
// import 'bootstrap' نباید لود بشه با ادمین - دیگه کار نمیکنه دراپ دان
import VueMeta from 'vue-meta'
import VueMixin from './core/libraries/VueMixin'
// import '@@/assets/css/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from "axios";
import {getCookie} from "./core/libraries/VueMethods";
import VueZoomer from 'vue-zoomer'
import '@@/core/coreMain'

axios.defaults.baseURL = config.baseUrl;
axios.defaults.headers.common['access-control-allow-origin']="*";
if (VueMethods.isPart('admin')) {
    if (getCookie("adminAuthorization")) {
        axios.defaults.headers.common["Authorization"] = getCookie("adminAuthorization");
    }
}



Vue.use(VueZoomer)
Vue.use(VueMeta);
Vue.use(NumericDirective);
Vue.component("pagination", pagination);
window.swal = swal;
Vue.config.productionTip = false;
Vue.prototype.$axios = Axios;
Vue.prototype.$ = $;
window.$ = $;
Vue.prototype.moment = window.moment = dayjs;
dayjs.extend(jalali)
dayjs.extend(relativeTime)
dayjs.locale('fa')
Vue.component("date-picker", VuePersianDatetimePicker);
Vue.component("multiselect", Multiselect);
Vue.use(Notifications);
Vue.use(VueRouter);
Vue.component("fulfilling-bouncing-circle-spinner", FulfillingBouncingCircleSpinner);
Vue.prototype.$store = store;

const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        this.app.lastScrollPosition = { x: window.scrollX, y: window.scrollY }
        if (to.path === from.path) {
            return savedPosition
        }
        // if (back) { return this.app.lastScrollPosition }

        return { x: 0, y: 0 }
    },
    mode: "history",
    routes: [...adminRoutes]
});
export {router};

router.beforeEach((to, from, next) => {
    if (to.meta.auth) {
        console.log(store.state)
    }
    if (to.path === from.path && to.hash === '#') {
        return next(false)
    }
    if (to.path.includes('admin') && !to.path.includes('login') && !getCookie('adminAuthorization')) {
        return next('/admin/login');
    }

    return next()
})

Vue.mixin(VueMixin)

const app = new Vue({
    data() {
        return {
            baseUrl: config.baseUrl,
            allPermissions: [],
            adminProfile: [],
            currentAdminPermissions: [],
            transitionName: 'drain',
            ckeditorLoaded: false,
            lastScrollPosition: { x: 0, y: 0 },
        };
    },

    created() {

        this.$axios.defaults.baseURL = config.baseUrl
        this.$axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                console.error(error);
                if (this.$axios.isCancel(error)) {
                    console.log('Axios canceled');
                    throw error;
                }
                if (error.response.status === 401) {
                    this.$notify({
                        group: "error",
                        title: "پیام سیستم",
                        text: 'توکن منقضی شده. لطفا مجددا وارد شوید',
                        type: "error",
                        duration: "5000",
                    });
                    if (VueMethods.isPart('admin')) {
                        VueMethods.deleteCookie('adminAuthorization')
                        router.push('/admin/login')
                    }
                }

                throw error;
            }
        );

        if (VueMethods.isPart('admin')) {
            if (this.getCookie("adminAuthorization")) {
                this.$axios.defaults.headers.common["Authorization"] = this.getCookie(
                    "adminAuthorization"
                );
            } else {
                router.push('/admin/login')
            }
        }

    },
    methods: {
        ...VueMethods,
    },
    watch: {
        $route(to) {
            document.title = to.meta.title ? config.title + ' - ' + to.meta.title : config.title;
            setTimeout(() => {
                document.title = to.meta.title ? config.title + ' - ' + to.meta.title : config.title;
            })
        },
    },
    render: (h) => h(App),
    router: router,
}).$mount("#app");

window.app = app;
