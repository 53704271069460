import Vue from "vue";

Vue.filter('priceinput' , function(num) {
  if(num) {
    // let englishNumber = this.convertToEnglishNumber(num);
    const result = num.toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result;
  } else {
    return ''
  }
})
