import menus from '@@/core/components/sidebar/menu.js'
export default {
  settingGroups: null,
  loading: false,
  menus: menus,
  permissions: null,
  admin: null,
  notifications: null,
  total_unread_notifications: 0,
  contacts: null,
  total_unread_contacts: 0
}
